<template>
  <div id="app">
    <!-- 图片列表 -->
    <div class="bg_green">
      <div class="white_box">

        <!-- 标题 -->
        <div class="title2 dark">{{ urlData.productName }}</div>

        <!-- 图片列表 -->
        <div class="pic_list2">
          <dl class="clear">
            <dt :style="{backgroundImage:`url(${pic1})`}" style="background-size: 100% 100%;"></dt>
            <dd v-if="urlData">规格: {{ urlData.specification }}</dd>
            <dd v-if="urlData">保质期: {{ urlData.guarantee }}</dd>
            <dd v-if="urlData">条码: {{ urlData.barcode }}</dd>
            <dd v-if="urlData">生产厂商: {{ urlData.factory }}</dd>
          </dl>
        </div>

      </div>
    </div>

    <!-- 条形码 -->
    <!--<div class="bar_code clear" v-if="urlData" style="text-align: center">-->
    <!--<svg id="barcode">{{urlData.barcode}}</svg>-->
    <!--</div>-->

    <!-- 图片列表 -->
    <div class="white_box mgt_3vw">

      <!-- 标题 -->
      <div class="title">
        <div class="title_l">溯源链条</div>
        <!--<div class="title_r iconfont">&#xeb08;</div>-->
      </div>

      <!-- 图片列表 -->
      <div class="pic_list2" v-if="urlData">
        <dl class="type2 clear" v-for="(option,index) in urlData.nodes" :key="index">
          <a :href="nutritiveTrace.traceUrl">
            <dt class="type2"
                :style="{backgroundImage:`url(${option.images.length>0?option.images[0]:pic2})`}"></dt>
            <dd class="type2 company">{{ option.company }}</dd>
            <dd class="type2" v-for="(el, indexNum) in option.items" :key="indexNum">
              <span>{{ el.typeName }}日期：{{ el.date.replace('/', '-').replace('/', '-') }}</span>
            </dd>
          </a>
        </dl>
      </div>

    </div>

    <div class="height_5vw"></div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>


<style scoped>

</style>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {NUTRIENT_SOURCE, NUTRIENT_SOURCE_TRACE} from "../store/mutation-types";
import {Dialog} from "vant";
import {appProd} from '@/config/env.dev'

export default {
  data() {
    return {
      pic1: require('../assets/images/pic_default.jpg'),
      pic2: require('../assets/images/pic_default.jpg'),
      // tab
      cur: 0,
      loading: false,
      id: null,
      rq: null,
      schoolName: null,
      materialId: null,
      material: {},
      urlData: {},

      nutritiveTrace: {}
    };
  },
  mounted() {
    this.customerUser = Vue.ls.get(ACCESS_CustomerUser)
    this.id = this.$route.query.id;
    this.rq = this.$route.query.rq;
    this.schoolName = this.customerUser.businessCustomerUser.business.name;
    this.materialId = this.$route.query.materialId;

    this.nutritiveTrace = Vue.ls.get(NUTRIENT_SOURCE_TRACE);

    this.nutritive = Vue.ls.get(NUTRIENT_SOURCE);
    if (!this.nutritive) {
      Dialog.alert({title: '餐食营养显示异常'})
          .then(() => {
            this.goBack();
          })
      return;
    }
    if (this.nutritive.thumbnailImage) {
      this.pic1 = this.nutritive.thumbnailImage;
    }

    this.loadNutritive()
  },
  methods: {
    loadNutritive() {
      // if (this.id && this.rq && this.schoolName) {
      //     this.loading = true;
      //     post('api/public/nutritive/v1/get/element/' + this.schoolName + '/' + this.rq, {}, false).then(res => {
      //         if (res.data && res.data.length > 0) {
      //             this.nutritive = res.data.find(item => item.id === this.id)
      //             this.material = this.nutritive.suyuan.find(item => item.material_id === this.materialId)
      //             this.loadMaterialUrl()
      //         }
      //     }).catch(() => {
      //         this.loading = false
      //     });
      // }
      this.loadMaterialUrl();
    },
    loadMaterialUrl() {
      this.loading = true;
      let url = this.nutritiveTrace.traceUrl.replace('122.144.216.4', 'd.fsytss.com');
      this.loading = true;
      post('api/public/suyuan/v1/query', {
        url: url
      }, false).then(res => {
        this.urlData = res.data
        //     console.log('this.urlData', this.urlData)
        var JsBarcode = require('jsbarcode')
        JsBarcode("#barcode", this.urlData.barcode);
        this.loading = false;
      }).catch(() => {
        this.loading = false
      });
    }
  }

};
</script>
